import { ReactNode } from "react";
import { Container } from "./style";
interface ITitlePage {
  title: string;
  children?: ReactNode;
}
export const TitlePage: React.FC<ITitlePage> = ({ title }) => {
  return (
    <Container>
      <h1>{title}</h1>
    </Container>
  );
};
