import { Button, Card, Col, Form, Input, Row } from "antd";

import { useAuth } from "../../../context/AuthContext";
import { ContainerForm } from "./style";

const LoginComponent = () => {
  const { handleSignIn } = useAuth();

  const onFinish = (values: any) => {
    handleSignIn({ username: values?.username, password: values?.password });
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Card
          type="inner"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: '90vh',
          }}
        >
          <ContainerForm>
            <Form
              layout="vertical"
              size="middle"
              name="basic"
              // labelCol={{ span: 8 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Usuário"
                name="username"
                rules={[
                  { required: true, message: "Entre com seu nome de usuário!" },
                ]}
              >
                <Input placeholder="Nome de usuário" />
              </Form.Item>

              <Form.Item
                label="Senha"
                name="password"
                rules={[{ required: true, message: "Entre com sua senha!" }]}
              >
                <Input.Password placeholder="Senha" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Entrar
                </Button>
              </Form.Item>
            </Form>
          </ContainerForm>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginComponent;
