import { Layout } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
`;

export const Sider = styled(Layout.Sider)``;
export const Content = styled(Layout.Content)`
  height: "100%" !important;
  padding: 10px;
`;
