import { ReactNode } from "react";
import { Container } from "./style";

interface Prop {
  title?: string;
  children?: ReactNode;
}
export const Header: React.FC<Prop> = ({ title, children }) => {
  return (
    <Container>
      <h1>{title}</h1>
      {children}
    </Container>
  );
};
