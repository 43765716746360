import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  .ant-descriptions-item-container {
  }

  .ant-descriptions-item {
    padding-bottom: 8px !important;
  }

  .ant-descriptions-item-label {
    font-weight: bold !important;
  }
  .ant-descriptions-item-content {
  }
`;
