export const ColumnsTableResumoCarregamentoVagao = (): any => {
  return [
    {
      title: String("Vagão").toUpperCase(),
      dataIndex: "vagao",
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    {
      title: String("Capacidade Máxima").toUpperCase(),
      dataIndex: "capacidadeMaxima",
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    {
      title: String("Peso Carregado").toUpperCase(),
      dataIndex: "peso",
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    // {
    //   title: "Tempo de Carga",
    //   dataIndex: "tempoCarga",
    //   responsive: ["xs", "sm", "md"],
    //   render: (value: any, record: any) => value || "",
    // },
  ];
};
