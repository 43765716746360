import { ReactNode } from "react";
import PoweredBy from "../PoweredBy";
import { Container } from "./style";

interface IFooter {
  versionMajor?: string;
  versionMinor?: string;
  versionPatch?: string;
  children?: ReactNode;
}
const Footer: React.FC<IFooter> = ({
  versionMajor,
  versionMinor,
  versionPatch,
}) => {
  return (
    <Container>
      <PoweredBy />
    </Container>
  );
};
export default Footer;
