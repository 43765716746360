import styled from "styled-components";

export const Wrapper = styled.div`
  width: "100%";
  min-height: 100%;
`;

export const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 15px;

  .upload-btn {
    background-color: #f0ad4e;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .upload-btn:hover {
    background-color: #ec971f;
  }

  h2 {
    margin-bottom: 15px;
    font-size: 1.4rem;
    font-weight: 500;
    color: #38389b;
  }

  /* Informações de Composição */
  .composicao-info p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #38389b;
  }

  .composicao-info p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #38389b;
  }

  /* Informação do Vagão */
  .vagao-info {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .info-box {
    flex: 1;
    background-color: #f0f4ff;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #dce3f3;
    transition: box-shadow 0.3s ease;
  }

  .info-box:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .info-box p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  /* Status da Pesagem */
  .status-box {
    text-align: center;
    padding: 15px;
    background-color: #e9f7ec;
    border: 1px solid #d4edda;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .status-box p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #28a745;
  }

  /* Lista de Vagões */
  .lista-vagoes-section .vagao-list {
    display: flex;
    flex-direction: column;
  }

  .vagao-item {
    padding: 15px;
    background-color: #f4f7ff;
    border-left: 5px solid #1e1e48;
    border-radius: 8px;
    transition: transform 0.3s ease;
    margin-bottom: 10px;
  }

  .vagao-item:hover {
    transform: scale(1.02);
  }

  .vagao-item h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #5152da;
  }

  .vagao-item ul {
    list-style-type: none;
    font-size: 1rem;
  }

  .vagao-item ul li {
    margin-bottom: 5px;
  }

  /* Botões Responsivos */
  @media (max-width: 768px) {
    .header,
    .vagao-info {
      flex-direction: column;
    }

    .upload-btn {
      margin-top: 10px;
      width: 100%;
      text-align: center;
    }

    .info-box {
      width: 100%;
    }
  }
`;
