import styled from "styled-components";

export const Container = styled.div`
  background-color: #0000;
`;

// parte clara: #FCFAF7
// cor da borda #DCCAC1
export const Title = styled.h1`
  display: flex;
  justify-content: center;
  border: 2px solid #dccac1;
  background-color: #efe6d6;
`;
export const Header = styled.div`
  border: 1px solid #dccac1;
  background-color: #fcfaf7;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const Box = styled.span`
  border: 1px solid #dccac1;
  background-color: #fcfaf7;
  flex: 1;
  flex-basis: 15%;
`;
export const Body = styled.div`
  margin: 0;
  width: 100%;

  & table td {
    width: 100%;
    border: 1px solid #dccac1;
    background-color: #fcfaf7;
    text-align: center;
  }
`;
