import moment from "moment-timezone";
import { ICarregamento } from "../../../../interfaces/ICarregamento";

interface IDescriptionListCarregamento {
  carregamento: ICarregamento;
}
export const DescriptionListCarregamento = ({
  carregamento,
}: IDescriptionListCarregamento) => {

  return [
    {
      key: "1",
      label: "Procedência",
      children: carregamento?.proprietarioId?.descricao || "",
    },
    {
      key: "2",
      label: "Material",
      children: carregamento?.produtoId?.descricao || "",
    },
    {
      key: "3",
      label: "Destino",
      children: carregamento?.destinoId?.descricao || "",
    },
    // {
    //   key: "4",
    //   label: "Peso NF-e",
    //   // span: 2,
    //   children: carregamento?.pesoNf || "",
    // },
    // {
    //   key: "5",
    //   label: "Fiscal",
    //   children: "",
    // },
    {
      key: "6",
      label: "Data",
      children: carregamento?.dataChegada
        ? moment(carregamento?.dataChegada).format("DD/MM/YYYY")
        : "",
    },
    {
      key: "7",
      label: "Horário",
      children: `${
        carregamento?.dataChegada
          ? moment(carregamento?.dataChegada).format("HH:mm")
          : ""
      } - ${
        carregamento?.dataSaida
          ? moment(carregamento?.dataSaida).format("HH:mm")
          : ""
      }`,
    },
    {
      key: "8",
      label: "Peso Carregado",
      children: carregamento?.pesoBalanca || "",
    },
    {
      key: "9",
      label: "Composição Vazia",
      children: carregamento?.prefixoEntrada || "",
    },
    {
      key: "10",
      label: "Composição Carregada",
      children: carregamento?.prefixoSaida || "",
    },
    {
      key: "11",
      label: "Tipo Composição",
      children: `${carregamento?.vagoes?.length || ""}`,
    },
    // {
    //   key: "12",
    //   label: "Chuva",
    //   children: carregamento?.chuva || "",
    // },
  ];
};
