import React from "react";
import { AuthProvider } from "./AuthContext";
import { BrokerProvider } from "./BrokerContext";
import { CarregamentoProvider } from "./CarregamentoContext";

type IProps = {
  children?: React.ReactNode;
};

const ContextWrapper: React.FC<IProps> = ({ children }) => {
  return (
    <AuthProvider>
      <BrokerProvider>
        <CarregamentoProvider>{children}</CarregamentoProvider>
      </BrokerProvider>
    </AuthProvider>
  );
};
export default ContextWrapper;
