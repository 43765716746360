import React from "react";
import Modal from "antd/es/modal/Modal";
import { ModalProps } from "antd";

interface ICModal {
  hiddenFooterButton?: boolean;
}
const CModal: React.FC<ModalProps & ICModal> = ({
  onCancel,
  onOk,
  title,
  children,
  cancelText,
  okText,
  open,
  width,
  hiddenFooterButton,
}) => {
  return (
    <Modal
      open={open}
      width={width}
      title={title}
      destroyOnClose={true}
      {...(onOk ? { onOk } : {})}
      {...(onCancel ? { onCancel } : {})}
      {...(cancelText ? { cancelText } : { cancelText: "Cancelar" })}
      {...(okText ? { okText } : { okText: "OK" })}
      {...(okText ? { okText } : { okText: "OK" })}
      {...(hiddenFooterButton ? { footer: null } : {})}
    >
      {children}
    </Modal>
  );
};

export default CModal;
