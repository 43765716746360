export const ColumnsTableResumoEvento = (): any => {
  return [
    {
      key: "evento",
      dataIndex: "evento",
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    {
      key: "tempo",
      dataIndex: "tempo",
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
  ];
};
