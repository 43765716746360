import React, { useCallback, useContext, useEffect, useState } from "react";

import api from "../services/api";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

type IProps = {
  children?: React.ReactNode;
};
interface ILoginData {
  username: string;
  password: string;
}

interface IAuthContext {
  loadingSession: boolean;
  authenticated: boolean;
  handleSignIn: (loginData: ILoginData) => void;
  handleSignOut: () => void;
  user: IUserInfo;
}

export interface IUserInfo {
  username: string;
  userId: string;
  profile: string;
  company: {
    code: string;
    fullname: string;
    logo: string;
  };
}

export const AuthContext = React.createContext<IAuthContext>(
  {} as IAuthContext
);

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingSession, setLoadingSession] = useState(false);
  const [user, setUser] = useState<IUserInfo>({} as any);

  const navigate = useNavigate();

  const handleSignOut = useCallback(() => {
    window.localStorage.removeItem("@App:token");
    window.localStorage.removeItem("@App:user");
    navigate("/p/login");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [navigate]);

  const handleGetUserInfo = ({ jwt }: { jwt: string }) => {
    setLoadingSession(true);
    api
      .get<IUserInfo>(`/user/getInfoUser`)
      .then(({ data }) => {
        window.localStorage.setItem("@App:user", JSON.stringify(data));
        setUser(data);
      })
      .catch(() =>
        message.error(`Não foi possível recuperar os dados do usuário`)
      )
      .finally(() => setLoadingSession(false));
  };

  const handleSignIn = useCallback(({ username, password }: ILoginData) => {
    setLoadingSession(true);
    api
      .post("/auth/login", {
        username,
        password,
      })
      .then((response) => {
        const { token } = response.data;
        if (token) {
          setAuthenticated(true);
          api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          window.localStorage.setItem("@App:token", token);
          handleGetUserInfo({ jwt: token });
        } else {
          message.error("Usuário ou senha inválida");
        }
      })
      .catch((e) => handleError(e))
      .finally(() => setLoadingSession(false));
  }, []);

  const handleError = (err: any) => {
    message.error("Algo deu errado, tente novemente!");
    setLoadingSession(false);
  };

  useEffect(() => {
    if (window.localStorage.getItem("@App:token")) {
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${window.localStorage.getItem("@App:token")}`;
      setUser(JSON.parse(window.localStorage.getItem("@App:user") as any));
      setAuthenticated(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loadingSession,
        authenticated,
        handleSignIn,
        handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => useContext(AuthContext);
