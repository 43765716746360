import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const OnlyPublicRoute: React.FC = () => {
  const { authenticated, loadingSession } = useAuth();

  if (loadingSession) {
    return <></>;
  }

  return !authenticated ? <Outlet /> : <Navigate to="/" />;
};

export default OnlyPublicRoute;
