import { List } from "./style";

interface IResumoTempo {
  loading?: boolean;
  data?: any[];
}
const ResumoTempo: React.FC<IResumoTempo> = ({ loading, data }) => (
  <List
    size="small"
    className="loadmore-list"
    loading={loading}
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item: any, index: any) => (
      <List.Item key={index}>
        <List.Item.Meta title={item.label} description={item.value} />
      </List.Item>
    )}
  />
);

export default ResumoTempo;
