import { Descriptions } from "antd";
import { DescriptionListCarregamento } from "../../constants/ResumoCarregamento";

interface IResumoCarregamento {
  loading?: boolean;
  data?: any;
}
const ResumoCarregamento: React.FC<IResumoCarregamento> = ({
  loading,
  data,
}) => (
  <Descriptions
    size="small"
    layout="horizontal"
    items={DescriptionListCarregamento({ carregamento: data })}
  />
);
export default ResumoCarregamento;
