import styled from "styled-components";
import { Divider as antDivider, Button as antButton } from "antd";

export const Container = styled.div`
  margin: 0;
  width: 100%;
  background-color: #f0f2f5;

  .ant-btn.ant-btn-sm {
    padding: 3px !important;
  }
`;

export const Title = styled.h1`
  /* font-size: 36px; */
`;

export const Action = styled.span`
  display: flex;
  justify-content: end;

  .ant-btn {
    margin-left: 2px;
    border-radius: 5px;
  }
`;
export const ActionGrid = styled.span``;

export const Divider = styled(antDivider)`
  .ant-divider-inner-text {
    font-size: 12px !important;
  }
`;

export const Button = styled(antButton)``;

export const HeaderTable = styled.div`
  padding: 10px;
  border-bottom: 1px solid #f0f2f5;
`;
