import { TableColumnsType, Tag } from "antd";

export const ColumnsTableWagon: TableColumnsType<any> = [
  {
    title: "Identificação Vagão",
    dataIndex: "identificacaoVagao",
    key: "identificacaoVagao",
    align: "center",
  },
  {
    title: "Ordem Vagão",
    dataIndex: "ordemVagao",
    key: "ordemVagao",
    align: "center",
  },
  {
    title: "Tipo do Vagão",
    dataIndex: "tipo",
    key: "tipo",
    align: "center",
    // render: (value: number) => Intl.NumberFormat("pt-BR").format(value),
  },
  {
    title: "Peso Permitido",
    dataIndex: "pesoMaximoPermitido",
    key: "pesoMaximoPermitido",
    align: "center",
    render: (value: number) => Intl.NumberFormat("pt-BR").format(value),
  },
  {
    title: "Peso Final",
    dataIndex: "pesoFinal",
    key: "pesoFinal",
    align: "center",
    render: (value: number) => Intl.NumberFormat("pt-BR").format(value),
  },
  {
    title: "Velocidade",
    dataIndex: "velocidadeMedia",
    key: "velocidadeMedia",
    align: "center",
    render: (value: number) =>
      Intl.NumberFormat("pt-BR").format(+Number(value).toFixed(0)),
  },
  {
    title: "Situação Vagão",
    // dataIndex: "vagoesComOcorrencia",
    key: "vagoesComOcorrencia",
    align: "center",
    render: (value: any) => {
      if (value.length > 0 || value?.alerta) {
        return <Tag color={"red"}>ALARMADO</Tag>;
      }
    },
  },
];
