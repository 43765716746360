import { ReactNode } from "react";
import { Container } from "./style";

interface Prop {
  title?: string;
  content?: string;
  children: ReactNode;
}
export const Section: React.FC<Prop> = ({ children, content, title }) => {
  return (
    <Container>
      <h2>{title}</h2>
      <div>{children}</div>
    </Container>
  );
};
