import { Col, Row, message } from "antd";
import { Container } from "./style";
import BDT from "../BDT";
import CCard from "../../../../components/Card";
import ResumoEvento from "../ResumoEvento";
import { useCallback, useEffect, useState } from "react";
import ResumoTempo from "../ResumoTempo";
import ResumoCarregamento from "../ResumoCarregamento";
import api from "../../../../services/api";
import { ICarregamento } from "../../../../interfaces/ICarregamento";
import ResumoCarregamentoVagao from "../ResumoCarregamentoVagao";
import ResumoEquipamento from "../ResumoEquipamento";

interface IBE {
  eventoCarregamento: any[];
  carregamento?: ICarregamento;
  loading: boolean;
  setLoading: (params: boolean) => void;
}

const BE: React.FC<IBE> = ({
  eventoCarregamento,
  carregamento,
  loading,
  setLoading,
}) => {
  // const [loading, setLoading] = useState<boolean>(false);
  const [resumoEventoList, setResumoEventoList] = useState<any[]>([]);
  const [resumoTempoList, setResumoTempoList] = useState<any[]>([]);
  const [resumoEquipamentoList, setResumoEquipamentoList] = useState<any[]>([]);
  const [resumoCarregamentoVagaoList, setResumoCarregamentoVagaoList] =
    useState<any[]>([]);

  const getResumoEvento = useCallback(() => {
    setLoading(true);
    api
      .get(
        `/eventoCarregamento/getResumoEventos?carregamentoId=${carregamento?._id}`
      )
      .then(({ data }) => {
        setResumoEventoList(data);
      })
      .catch(() => message.error(`Erro ao buscar o Resumo dos Eventos`))
      .finally(() => setLoading(false));
  }, [carregamento]);

  const getResumoTempo = useCallback(() => {
    setLoading(true);
    api
      .get(
        `/eventoCarregamento/getResumoTemposOperacionais?carregamentoId=${carregamento?._id}`
      )
      .then(({ data }) => {
        setResumoTempoList(data);
      })
      .catch(() => message.error(`Erro ao buscar o Resumo dos Tempos`))
      .finally(() => setLoading(false));

    setLoading(false);
  }, [carregamento]);

  const getResumoEquipamento = useCallback(() => {
    setLoading(true);
    api
      .get(
        `/operacaoCarregamento/getResumoOperacaoCarregamentoEquipamento?carregamentoId=${carregamento?._id}`
      )
      .then(({ data }) => {
        setResumoEquipamentoList(data);
      })
      .catch(() =>
        message.error(
          `Erro ao buscar o Resumo das Operacoes de Carregamento Por Equipamento`
        )
      )
      .finally(() => setLoading(false));
  }, [carregamento]);

  const getResumoCarregamentoVagao = useCallback(() => {
    setLoading(true);
    api
      .get(
        `/operacaoCarregamento/getResumoOperacaoCarregamento?carregamentoId=${carregamento?._id}`
      )
      .then(({ data }) => {
        setResumoCarregamentoVagaoList(data);
      })
      .catch(() => message.error(`Erro ao buscar o Resumo do Carregamento`))
      .finally(() => setLoading(false));
  }, [carregamento]);

  useEffect(() => {
    getResumoEvento();
  }, [carregamento]);

  useEffect(() => {
    getResumoTempo();
  }, [carregamento]);

  useEffect(() => {
    getResumoEquipamento();
  }, [carregamento]);

  useEffect(() => {
    getResumoCarregamentoVagao();
  }, [carregamento]);

  return (
    <Container>
      <Row gutter={[2, 4]}>
        <Col span={24}>
          <CCard
            title="Resumo do Carregamento"
            children={
              <ResumoCarregamento loading={loading} data={carregamento} />
            }
          />
        </Col>

        <Col span={8} style={{ display: "flex" }}>
          <CCard
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            title="Eventos do Carregamento"
            children={
              <BDT
                loading={loading}
                eventoCarregamento={eventoCarregamento}
                showBtnDelete={false}
                showBtnEdit={false}
              />
            }
          />
        </Col>

        <Col span={8} style={{ display: "flex" }}>
          <CCard
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            title="Resumo dos Eventos"
            children={
              <ResumoEvento loading={loading} data={resumoEventoList} />
            }
          />
        </Col>

        <Col span={8} style={{ display: "flex" }}>
          <CCard
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            title="Resumo dos Tempos Operacionais"
            children={<ResumoTempo loading={loading} data={resumoTempoList} />}
          />
        </Col>

        <Col span={16} style={{ display: "flex" }}>
          <CCard
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            title="Resumo Operação do Carregamento"
            children={
              <ResumoCarregamentoVagao
                loading={loading}
                data={resumoCarregamentoVagaoList}
              />
            }
          />
        </Col>

        <Col span={8} style={{ display: "flex" }}>
          <CCard
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            title="Resumo de Equipamentos"
            children={
              <ResumoEquipamento
                loading={loading}
                data={resumoEquipamentoList}
              />
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default BE;
