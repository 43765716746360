import { ConfigProvider } from "antd";
import ContextWrapper from "./context/ContextWrapper";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import GlobalStyle from "./theme/globalStyle";

function App() {
  return (
    <ConfigProvider renderEmpty={() => "Nenhum dado encontrado"}>
      <BrowserRouter future={{ v7_startTransition: true }} basename="/">
        <ContextWrapper>
          <GlobalStyle />
          <Routes />
        </ContextWrapper>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
