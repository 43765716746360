import { useEffect, useState } from "react";
import { Col, Row, Table, message } from "antd";
import { Container, Action, Divider, Button } from "./style";
import {
  ColumnsTableCarregamento,
  EditCarregamentoForm,
  FilterForm,
  NewCarregamentoForm,
} from "../../constants/Carregamento";
import { TitlePage } from "../../../../components/TitlePage/TitlePage";
import { AiOutlinePlus } from "react-icons/ai";
import CModal from "../../../../components/Modal/Modal";
import api from "../../../../services/api";
import { ICarregamento } from "../../../../interfaces/ICarregamento";

const CarregamentoComponent = () => {
  const [loading, setLoading] = useState(false);
  const [carregamento, setCarregamento] = useState<ICarregamento>();
  const [carregamentos, setCarregamentos] = useState<ICarregamento[]>([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const onGetData = () => {
    setLoading(true);
    api
      .get<ICarregamento[]>(`/carregamento`)
      .then(({ data }: any) => setCarregamentos(data))
      .catch((e: any) => message.error(e.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    onGetData();
  }, []);

  const onSearch = (params: any) => console.log("onSearch", params);

  const onSave = (params: any) => {
    setLoading(true);
    api
      .post(`/carregamento`, {
        ...params,
        qtdVagao:
          String(params?.qtdVagao).length > 0
            ? params?.qtdVagao
            : (params?.vagoes || []).length,
      })
      .then(({ data }) => {
        message.success("Carregamento salvo com sucesso");
        onGetData();
        setShowModalNew(false);
      })
      .catch(() => message.error("Erro ao salvar o carregamento"))
      .finally(() => setLoading(false));
  };

  const onUpdate = (params: ICarregamento) => {
    setLoading(true);
    api
      .put(`/carregamento/${params._id}`, {
        ...params,
        qtdVagao:
          String(params?.qtdVagao).length > 0
            ? params?.qtdVagao
            : (params?.vagoes || []).length,
      })
      .then(({ data }) => {
        onGetData();
        message.success("Carregamento atualizado com sucesso");
        setShowModalEdit(false);
      })
      .catch(() => message.error("Erro ao salvar o carregamento"))
      .finally(() => setLoading(false));
  };

  const onEdit = (params: any) => {
    setCarregamento(params);
    setShowModalEdit(true);
  };

  const onDelete = (params: ICarregamento) => {
    setLoading(true);
    api
      .delete(`/carregamento/${params._id}`)
      .then((_) => {
        message.success("Carregamento excluido com sucesso");
        onGetData();
      })
      .catch(() => message.error("Erro ao excluiir o carregamento"))
      .finally(() => setLoading(false));
  };

  const onShow = (params: ICarregamento) => {
    console.log("onShow", params);
  };

  return (
    <Container>
      <Row>
        <Col span={24}>
          <TitlePage title="Carregamento Ferroviário" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Action>
            <Button
              loading={loading}
              type="primary"
              icon={<AiOutlinePlus size={16} />}
              onClick={() => setShowModalNew(true)}
            >
              Novo
            </Button>
          </Action>
          <Divider type="horizontal" orientation="left">
            Pesquisar Por
          </Divider>
        </Col>
        <Col span={24}>{FilterForm({ onFinish: onSearch })}</Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            loading={loading}
            dataSource={carregamentos}
            columns={ColumnsTableCarregamento({
              carregamentos,
              setCarregamento,
              onDelete,
              onEdit,
              onShow,
            })}
          />
        </Col>
      </Row>

      <CModal
        open={showModalNew}
        title="Novo Carregamento"
        width={1000}
        onCancel={() => setShowModalNew(false)}
        children={NewCarregamentoForm({ onFinish: onSave })}
        hiddenFooterButton={true}
      />
      <CModal
        open={showModalEdit}
        title="Editar Carregamento"
        width={1000}
        onCancel={() => setShowModalEdit(false)}
        children={EditCarregamentoForm({
          onFinish: onUpdate,
          data: carregamento,
        })}
        hiddenFooterButton={true}
      />
    </Container>
  );
};

export default CarregamentoComponent;
