import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import {
  Button,
  Row,
  Flex,
  Form,
  DatePicker,
  Input,
  Transfer,
  Select,
  FormInstance,
  UploadProps,
  Upload,
  message,
  Slider,
  Spin,
} from "antd";
import { CiTrash, CiSearch, CiFloppyDisk, CiEdit } from "react-icons/ci";
import { Action } from "../../pages/Carregamento/style";
import api from "../../../../services/api";
import { NavLink } from "react-router-dom";
import { TransferDirection, TransferListProps } from "antd/es/transfer";
import { UploadOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { IVagao } from "../../../BalancaFerroviaria/interfaces/IPesagemFerroviariaV2";
import { IProprietario } from "../../../../interfaces/IProprietario";
import { IProduto } from "../../../../interfaces/IProduto";
import { IDestino } from "../../../../interfaces/IDestino";
import { IMaquina } from "../../../../interfaces/IMaquina";
import { UploadAction } from "./style";
import dayjs from "dayjs";
import { PiEyeThin } from "react-icons/pi";

interface ITableCarregamento {
  carregamentos: any[];
  setCarregamento?: any;
  onEdit?: (params?: any) => void;
  onDelete?: (params?: any) => void;
  onShow?: (params?: any) => void;
}
interface IForm {
  data?: any;
  onFinish?: (data: any) => void;
  form?: FormInstance<any>;
}
interface IFormFields {
  data?: any;
  loading?: boolean;
  isEditAction?: boolean;
  setLoading?: (loading: boolean) => void;
}

export const ColumnsTableCarregamento = ({
  carregamentos,
  onDelete,
  onEdit,
}: ITableCarregamento): any => {
  const [codigoBoletimEmbarque, setCodigoBoletimEmbarque] = useState<any[]>([]);
  const [prefixoSaida, setPrefixoSaida] = useState<any[]>([]);
  const [proprietario, setProprietario] = useState<any[]>([]);

  useEffect(() => {
    const listCodigoBoletimEmbarque: any = [];
    const listPrefixoSaida: any = [];
    let listProprietario: any = [];
    let listDestino: any = [];

    (carregamentos || []).map((e: any) => {
      listCodigoBoletimEmbarque.push({
        text: e.codigoBoletimEmbarque,
        value: e.codigoBoletimEmbarque,
      });
      listPrefixoSaida.push({ text: e.prefixo_saida, value: e.prefixo_saida });
      listProprietario.push({
        text: e?.proprietario?.descricao,
        value: e?.proprietario?.descricao,
      });
      listDestino.push({
        text: e?.destino?.descricao,
        value: e?.destino?.descricao,
      });
    });

    setCodigoBoletimEmbarque(listCodigoBoletimEmbarque);
    setPrefixoSaida(listPrefixoSaida);
    setProprietario(
      Object.keys(_.groupBy(listProprietario, "text")).map((e) => {
        return { text: e, value: e };
      })
    );
  }, []);

  return [
    {
      key: "dataChegada",
      dataIndex: "dataChegada",
      title: String("Data").toUpperCase(),
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>
          {moment(value).format("DD/MM/YYYY")}
        </NavLink>
      ),
    },
    {
      key: "codigoBoletimEmbarque",
      dataIndex: "codigoBoletimEmbarque",
      title: String("Nº BDT").toUpperCase(),
      onFilter: (value: any, record: any) =>
        record.codigoBoletimEmbarque === value,
      filterSearch: true,
      filters: codigoBoletimEmbarque,
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>{value}</NavLink>
      ),
    },
    {
      key: "prefixoSaida",
      dataIndex: "prefixoSaida",
      title: String("Prefixo").toUpperCase(),
      onFilter: (value: any, record: any): any =>
        String(record.prefixoSaida).startsWith(value),
      filterSearch: true,
      filters: prefixoSaida,
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>{value}</NavLink>
      ),
    },
    {
      key: "proprietarioId",
      dataIndex: "proprietarioId",
      title: String("Proprietário").toUpperCase(),
      onFilter: (value: any, record: any) =>
        record.proprietarioId.descricao.startsWith(value),
      filterSearch: true,
      filters: proprietario,
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>
          {value?.descricao}
        </NavLink>
      ),
    },

    {
      key: "destinoId",
      dataIndex: "destinoId",
      title: String("Destino").toUpperCase(),
      onFilter: (value: any, record: any) => record.destinoId.startsWith(value),
      filterSearch: true,
      filters: codigoBoletimEmbarque,
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>
          {value?.descricao}
        </NavLink>
      ),
    },
    {
      dataIndex: "pesoBalanca",
      title: String("Peso Balança").toUpperCase(),
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>
          {value ? Intl.NumberFormat("pt-BR").format(value) : ""}
        </NavLink>
      ),
    },
    {
      dataIndex: "pesoMinimo",
      title: String("Peso Mínimo").toUpperCase(),
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>
          {value ? Intl.NumberFormat("pt-BR").format(value) : ""}
        </NavLink>
      ),
    },
    {
      dataIndex: "pesoMaximo",
      title: String("Peso Máximo").toUpperCase(),
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>
          {value ? Intl.NumberFormat("pt-BR").format(value) : ""}
        </NavLink>
      ),
    },
    {
      dataIndex: "maquinas",
      title: String("Máquinas em Operação").toUpperCase(),
      render: (value: any, record: any) => (
        <NavLink to={`/detalhesCarregamento/${record._id}`}>
          {(value || []).length}
        </NavLink>
      ),
    },
    {
      title: "#",
      dataIndex: "",
      key: "x",
      fixed: "right",
      render: (text: any, record: any, index: any) => (
        <Flex wrap="wrap" gap="small">
          <NavLink
            to={`/detalhesCarregamento/${record._id}`}
            children={
              <Button
                icon={
                  <PiEyeThin
                    // color=""
                    size={16}
                    // onClick={() => onEdit!(record)}
                  />
                }
              />
            }
          />

          <Button
            icon={
              <CiEdit
                // color=""
                size={16}
              />
            }
            onClick={() => onEdit!(record)}
          />
          <Button
            icon={<CiTrash color="red" size={16} />}
            onClick={() => onDelete!(record)}
          />
        </Flex>
      ),
    },
  ];
};

export const FilterForm = ({ onFinish }: IForm) => {
  type LayoutType = Parameters<typeof Form>[0]["layout"];

  const [formLayout, setFormLayout] = useState<LayoutType>("horizontal");
  const [form] = Form.useForm();

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 3 }, wrapperCol: { span: 24 } }
      : null;

  return (
    <Form
      {...formItemLayout}
      layout={"horizontal"}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item label="Data">
        <DatePicker.RangePicker
          placeholder={["Data Inicial", "Data Final"]}
          format={["DD/MM/YYYY"]}
        />
      </Form.Item>
      <Form.Item label="Boletim">
        <Input placeholder="Boletim" />
      </Form.Item>
      <Form.Item label="Prefixo">
        <Input placeholder="Prefixo" />
      </Form.Item>

      <Form.Item>
        <Action>
          <Button type="default" icon={<CiSearch size={16} />}>
            Pesquisar
          </Button>
        </Action>
      </Form.Item>
    </Form>
  );
};

export const CarregamentoFields = ({
  data,
  loading,
  setLoading,
  isEditAction,
}: IFormFields) => {
  const [equipamento, setEquipamento] = useState<any[]>([]);
  const [vagao, setVagao] = useState<IVagao[]>([]);
  const [proprietario, setProprietario] = useState<any[]>([]);
  const [produto, setProduto] = useState<any[]>([]);
  const [destino, setDestino] = useState<any[]>([]);
  const [targetKeysMaquinas, setTargetKeysMaquinas] = useState<string[]>([]);
  const [targetKeysVagoes, setTargetKeysVagao] = useState<string[]>([]);

  const filterOption = (inputValue: string, option: any) =>
    option.title.startsWith(inputValue);

  const handleChangeMaquinas = (newTargetKeys: string[]) =>
    setTargetKeysMaquinas(newTargetKeys);

  const handleChangeVagao = (newTargetKeys: string[]) =>
    setTargetKeysVagao(newTargetKeys);

  const uploadProps: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_MAXIMUS_APP_BACKEND}/carregamento/uploadPlanilhaVagao`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("@App:token")}`,
    },
    onChange(info: any) {
      console.log(info);

      setLoading!(true);
      if (info.file.status === "done") {
        const listVagao = (info?.["file"]?.response?.data || []).map(
          (e: any) => {
            return { key: e?._id, title: e?.codigoVagao };
          }
        );
        setVagao(listVagao);

        message.success(info?.["file"]?.response?.message);
        setLoading!(false);
      } else if (info.file.status === "error") {
        message.error(info?.["file"]?.response?.message);
        setLoading!(false);
      } else if (info.file.status === "removed") {
        setLoading!(false);
      }
    },
  };

  useEffect(() => {
    api.get<IMaquina[]>(`/equipamento`).then(({ data }) => {
      const listEquipamentos = (data || []).map((e) => {
        return { key: e?._id, title: e?.descricao };
      });
      setEquipamento(listEquipamentos);
    });
  }, []);

  useEffect(() => {
    api.get<IProprietario[]>(`/proprietario`).then(({ data }) => {
      const listProprietario = (data || []).map((e) => {
        return { value: e?._id, label: e?.descricao };
      });
      setProprietario(listProprietario);
    });
  }, []);

  useEffect(() => {
    api.get<IProduto[]>(`/produto`).then(({ data }) => {
      const listProduto = (data || []).map((e) => {
        return { value: e?._id, label: e?.descricao };
      });
      setProduto(listProduto);
    });
  }, []);

  useEffect(() => {
    api.get<IDestino[]>(`/destino`).then(({ data }) => {
      const listDestino = (data || []).map((e) => {
        return { value: e?._id, label: e?.descricao };
      });
      setDestino(listDestino);
    });
  }, []);

  useEffect(() => {
    if (isEditAction) {
      setTargetKeysMaquinas(data?.maquinas.map((e: any) => e._id));
      setTargetKeysVagao(data?.vagoes.map((e: any) => e));
    }
  }, [data]);

  return [
    {
      label: "Id",
      name: "_id",
      hidden: true,
      component: {
        type: Input,
        props: {
          hidden: true,
        },
      },
    },
    {
      label: "Data Chegada",
      name: "dataChegada",
      component: {
        type: DatePicker,
        props: {
          style: { width: "100%" },
          showTime: { format: "HH:mm" },
          format: "DD/MM/YYYY HH:mm",
        },
      },
    },
    {
      label: "Data Saída",
      name: "dataSaida",
      component: {
        type: DatePicker,
        props: {
          style: { width: "100%" },
          showTime: { format: "HH:mm" },
          format: "DD/MM/YYYY HH:mm",
        },
      },
    },
    {
      label: "Prefixo Entrada",
      name: "prefixoEntrada",
      component: {
        type: Input,
        props: {
          style: { width: "100%" },
          placeholder: "Prefixo Entrada",
        },
      },
    },
    {
      label: "Prefixo Saída",
      name: "prefixoSaida",
      component: {
        type: Input,
        props: {
          style: { width: "100%" },
          placeholder: "Prefixo Entrada",
        },
      },
    },
    {
      label: "Proprietário",
      name: "proprietarioId",
      component: {
        type: Select,
        props: {
          style: { width: "100%" },
          placeholder: "Selecione",
          options: proprietario,
        },
      },
    },
    {
      label: "Produto",
      name: "produtoId",
      component: {
        type: Select,
        props: {
          style: { width: "100%" },
          placeholder: "Selecione",
          options: produto,
        },
      },
    },
    {
      label: "Destino",
      name: "destinoId",
      component: {
        type: Select,
        props: {
          style: { width: "100%" },
          placeholder: "Selecione",
          options: destino,
        },
      },
    },
    {
      label: "Qtd Vagões",
      name: "qtdVagao",
      component: {
        type: Input,
        props: {
          style: { width: "100%" },
          placeholder: "Qtd Vagões",
        },
      },
    },
    {
      label: "Range de pesos",
      name: "pesoMinimo",
      component: {
        type: Slider,
        props: {
          range: true,
          max: 150,
          min: 50,
          tooltip: { open: true },
          step: 1,
          // defaultValue: [20, 50],
          style: { width: "100%" },
          placeholder: "Peso Mínimo",
        },
      },
    },
    {
      label: "Máquinas",
      name: "maquinas",
      component: {
        type: Transfer,
        props: {
          listStyle: {
            width: "50%",
          },
          dataSource: equipamento,
          showSearch: true,
          filterOption: filterOption,
          onChange: handleChangeMaquinas,
          targetKeys: targetKeysMaquinas,
          render: (item: any) => {
            return item.title;
          },
        },
      },
    },
    {
      label: "Vagões",
      name: "vagoes",
      component: {
        type: Transfer,
        props: {
          listStyle: {
            width: "50%",
          },
          dataSource: vagao,
          showSearch: true,
          filterOption: filterOption,
          onChange: handleChangeVagao,
          targetKeys: targetKeysVagoes,
          render: (item: any) => item.title,
          showUploadList: false,
          footer: (
            _: TransferListProps<any>,
            {
              direction,
            }: {
              direction: TransferDirection;
            }
          ) =>
            direction === "left" ? (
              <UploadAction>
                <Upload {...uploadProps}>
                  <Button
                    icon={<UploadOutlined />}
                    size="small"
                    style={{ float: "right", margin: 4 }}
                  >
                    Importar Planilha
                  </Button>
                </Upload>
                {/* <Button
                  icon={<AiOutlineApi />}
                  size="small"
                  style={{ float: "right", margin: 4 }}
                >
                  Importar ACM Carga MRS
                </Button> */}
              </UploadAction>
            ) : (
              ""
            ),
        },
      },
    },
  ];
};

export const NewCarregamentoForm = ({ onFinish }: IForm) => {
  const [loading, setLoading] = useState(false);

  const [form] = useForm();

  type LayoutType = Parameters<typeof Form>[0]["layout"];
  const [formLayout, setFormLayout] = useState<LayoutType>("horizontal");

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 3 }, wrapperCol: { span: 24 } }
      : null;

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Form
        {...formItemLayout}
        layout={"horizontal"}
        form={form}
        onFinish={onFinish}
      >
        {CarregamentoFields({ setLoading: setLoading }).map((e, index) => (
          <Form.Item
            key={index}
            {...e}
            children={React.createElement(
              e.component.type as any,
              e.component.props as any
            )}
          />
        ))}
        <Form.Item>
          <Row justify={"end"}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<CiFloppyDisk size={16} />}
            >
              Salvar
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export const EditCarregamentoForm = ({ onFinish, data }: IForm) => {
  const [loading, setLoading] = useState(false);

  const [form] = useForm();

  type LayoutType = Parameters<typeof Form>[0]["layout"];
  const [formLayout, setFormLayout] = useState<LayoutType>("horizontal");

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 3 }, wrapperCol: { span: 24 } }
      : null;

  useEffect(() => {
    form?.setFieldsValue({
      ...data,
      destinoId: data?.destinoId?._id,
      produtoId: data?.produtoId?._id,
      proprietarioId: data?.proprietarioId?._id,
      maquinas: data?.maquinas?.map((e: any) => e?._id),
      dataChegada: dayjs(data?.dataChegada),
      pesoMinimo: [data?.pesoMinimo || 0, data?.pesoMaximo || 0],
    });
  }, [data]);

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Form
        {...formItemLayout}
        layout={"horizontal"}
        form={form}
        onFinish={onFinish}
      >
        {CarregamentoFields({
          setLoading: setLoading,
          isEditAction: true,
          data,
        }).map((e, index) => (
          <Form.Item
            key={index}
            {...e}
            children={React.createElement(
              e.component.type as any,
              e.component.props as any
            )}
          />
        ))}
        <Form.Item>
          <Row justify={"end"}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<CiFloppyDisk size={16} />}
            >
              Salvar
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
