import { ColumnsTableResumoEvento } from "../../constants/ResumoEvento";
import { Table } from "./style";

interface IResumoEvento {
  loading?: boolean;
  data?: any[];
}
const ResumoEvento: React.FC<IResumoEvento> = ({ loading, data }) => (
  <Table
    key={"evento"}
    size="small"
    loading={loading}
    columns={ColumnsTableResumoEvento()}
    dataSource={data}
  />
);

export default ResumoEvento;
