import styled from "styled-components";

export const ContainerForm = styled.div`
  width: 400px;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
