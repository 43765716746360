import { Table } from "antd";
import { ColumnsTableBDT } from "../../constants/BDT";

interface IBDT {
  loading: boolean;
  eventoCarregamento: any[];
  title?: any;
  onDelete?: (params?: any) => void;
  onEdit?: (params?: any) => void;
  showBtnDelete?: boolean;
  showBtnEdit?: boolean;
}

const BDT: React.FC<IBDT> = ({
  eventoCarregamento,
  loading,
  title,
  onDelete,
  onEdit,
  showBtnDelete,
  showBtnEdit,
}) => (
  <Table
    title={title}
    size="small"
    loading={loading}
    rowKey={"_id"}
    dataSource={eventoCarregamento}
    columns={ColumnsTableBDT({
      onDelete,
      onEdit,
      showBtnDelete,
      showBtnEdit,
    })}
    scroll={{ x: "100%" }}
  />
);

export default BDT;
