import moment from "moment-timezone";
import { IOperacaoCarregamento } from "../../../../interfaces/IOperacaoCarregamento";

interface ITableCarregamento {
  data?: IOperacaoCarregamento[];
  onEdit?: (params?: any) => void;
  onDelete?: (params?: any) => void;
  onShow?: (params?: any) => void;
}

export const ColumnsTableOperacaoCarregamento: any = () => {
  return [
    {
      // key: "data",
      dataIndex: "data",
      title: String("Data").toUpperCase(),
      render: (value: any, record: any) =>
        moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      // key: "produtoId",
      dataIndex: "produtoId",
      title: String("Produto").toUpperCase(),
      render: (value: any, record: any) => value?.descricao,
    },
    {
      // key: "equipamentoId",
      dataIndex: "equipamentoId",
      title: String("Máquina").toUpperCase(),
      render: (value: any, record: any) => value?.descricao,
    },
    {
      // key: "vagaoId",
      dataIndex: "vagaoId",
      title: String("Vagão").toUpperCase(),
      render: (value: any, record: any) => value?.codigoVagao,
    },
    {
      // key: "peso",
      dataIndex: "peso",
      title: String("Peso").toUpperCase(),
      render: (value: any, record: any) => value,
    },
    {
      // key: "numeroPesagem",
      dataIndex: "numeroPesagem",
      title: String("numeroPesagem").toUpperCase(),
      render: (value: any, record: any) => value,
    },
    // {
    //   key: "Produto",
    //   dataIndex: "produtoId",
    //   title: String("Produto").toUpperCase(),
    //   render: (value: any, record: any) => value?.descricao,
    // },
    // {
    //   title: "#",
    //   dataIndex: "",
    //   key: "x",
    //   fixed: "right",
    //   render: (text: any, record: any, index: any) => (
    //     <Flex wrap="wrap" gap="small">
    //       <Button
    //         icon={
    //           <CiEdit
    //             // color=""
    //             size={16}
    //           />
    //         }
    //         onClick={() => onEdit!(record)}
    //       />
    //       <Button
    //         icon={<CiTrash color="red" size={16} />}
    //         onClick={() => onDelete!(record)}
    //       />
    //     </Flex>
    //   ),
    // },
  ];
};
