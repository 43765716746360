import axios from "axios";

const api = axios.create({
  baseURL:
    (String(process.env.REACT_APP_MAXIMUS_APP_BACKEND) ||
    "http://localhost:3333"),
      headers: {
        'Cache-Control': 'no-cache'
      }
});


export default api;
