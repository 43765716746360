import { Table } from "antd";
import { ColumnsTableOperacaoCarregamento } from "../../constants/OperacaoCarregamento";
import { IOperacaoCarregamento } from "../../../../interfaces/IOperacaoCarregamento";

interface IProp {
  loading?: boolean;
  data: IOperacaoCarregamento[];
}
const OperacaoCarregamento: React.FC<IProp> = ({ data, loading }) => {
  return (
    <Table
      size="small"
      key={"_id"}
      loading={loading}
      dataSource={data}
      columns={ColumnsTableOperacaoCarregamento({ data })}
    />
  );
};

export default OperacaoCarregamento;
