import { ReactNode } from "react";

interface IPoweredBy {
  children?: ReactNode;
}
const PoweredBy: React.FC<IPoweredBy> = ({ children }) => (
  <p>
    Powered by <b>Codar Sistemas</b> - {new Date().getFullYear()}
  </p>
);

export default PoweredBy;
