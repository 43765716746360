import styled from "styled-components";
import { Divider as antDivider, Button as antButton } from "antd";

export const Container = styled.div`
  margin: 0;
  width: 100%;

  &.ant-transfer {
    &.ant-transfer-list {
      color: green;
    }
  }
  .ant-btn-icon {
    margin-top: 4px !important;
  }
`;

export const Title = styled.h1`
  /* font-size: 36px; */
`;

export const Action = styled.span`
  display: flex;
  justify-content: end;
`;
export const ActionGrid = styled.span``;

export const Divider = styled(antDivider)`
  .ant-divider-inner-text {
    font-size: 12px !important;
  }
`;

export const Button = styled(antButton)``;
