import styled from "styled-components";
import { List as cList } from "antd";

export const List = styled(cList)`
  width: 100%;

  .loadmore-list {
    min-height: 350px;
  }

  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-title {
    font-size: 8px !important;
  }
  .ant-list-item-meta-description {
    font-size: 12px !important;
  }
`;
