import { Layout, Typography } from "antd";
import styled from "styled-components";

export const Container = styled(Layout.Header)`
  display: flex;
  align-items: center;
`;

export const ContainerIcon = styled.span`
  margin: 0 !important;
  padding: 0 !important;
  /* color: "#fffff" !important; */
`;

export const Text = styled(Typography.Text)`
  margin: 0 auto !important;
  font-size: 24px !important;
  /* color: var(--codar-font-color-light) !important; */
`;

export const Logo = styled.div`
  height: 32px;
  margin: 0 0 0 0;
  padding: 32px;
  display: flex;
  align-items: center;
  background-color: rgba(230, 230, 230, 0.863);

`;
