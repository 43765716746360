import React, { useContext, useState } from "react";
import { ICarregamento } from "../interfaces/ICarregamento";

type IProps = {
  children?: React.ReactNode;
};

interface ICarregamentoContext {
  currentCarregamento: ICarregamento;
  setCurrentCarregamento: (carregamento: ICarregamento) => void;
}
export const CarregamentoContext = React.createContext<ICarregamentoContext>(
  {} as ICarregamentoContext
);

export const CarregamentoProvider: React.FC<IProps> = ({ children }) => {
  const [currentCarregamento, setCurrentCarregamento] = useState<
    ICarregamento | any
  >({} as any);

  return (
    <CarregamentoContext.Provider
      value={{
        currentCarregamento,
        setCurrentCarregamento,
      }}
    >
      {children}
    </CarregamentoContext.Provider>
  );
};

export const useCarregamento = (): ICarregamentoContext =>
  useContext(CarregamentoContext);
