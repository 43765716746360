import styled from "styled-components";

export const Container = styled.div`
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
`;
