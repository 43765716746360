import styled from "styled-components";

export const Container = styled.section`
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 15px;
    font-size: 1.4rem;
    font-weight: 500;
    color: #38389b;
  }
`;
