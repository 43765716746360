export const ColumnsTableResumoEquipamento = (): any => {
  return [
    {
      title: String("Máquina").toUpperCase(),
      dataIndex: "equipamento",
      responsive: ["xs", "sm", "md"],
      // render: (value: any, record: any) => value || "",
    },
    {
      title: String("qtd Vagão Carregado").toUpperCase(),
      dataIndex: "quantidadeVagaoCarregado",
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    // {
    //   title: String("Tempo médio Vagão").toUpperCase(),
    //   dataIndex: "tempoMedioCargaVagao",
    //   responsive: ["xs", "sm", "md"],
    //   render: (value: any, record: any) => value || "",
    // },
  ];
};
