import styled from "styled-components";

export const Container = styled.header`
  /* Cabeçalho */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1e48;
  color: white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;

  > h1 {
    font-size: 1.8rem;
    font-weight: 600;
  }
`;
