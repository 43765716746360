import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 64px;
  background-color: rgba(230, 230, 230, 0.863);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Image = styled.img`
  height: auto;
  width: 65%;
`;
