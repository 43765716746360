import { ColumnsTableResumoCarregamentoVagao } from "../../constants/ResumoCarregamentoVagao";
import { Table } from "./style";

interface IResumoCarregamentoVagao {
  loading?: boolean;
  data?: any[];
}
const ResumoCarregamentoVagao: React.FC<IResumoCarregamentoVagao> = ({
  loading,
  data,
}) => (
  <Table
    key={"vagao"}
    size="small"
    loading={loading}
    columns={ColumnsTableResumoCarregamentoVagao()}
    dataSource={data}
    expandable={{
      expandedRowRender: (record: any) => (
        <Table
          size="small"
          bordered
          dataSource={record?.equipamento || []}
          columns={[
            { title: "Máquina", dataIndex: "equipamento" },
            { title: "Peso", dataIndex: "peso" },
            { title: "Quantidade de Pesagem", dataIndex: "quantidadePesagem" },
          ]}
        />
      ),
      rowExpandable: (record: any) => record.maquina !== "Not Expandable",
    }}
  />
);

export default ResumoCarregamentoVagao;
