import "./style.ts";
import TcsmgLogo from "../../assets/tcsmg/logo-tcs-01.png";

import { Container, Image } from "./style";

const LogoComponent = () => {
  return (
    <Container>
      <Image src={TcsmgLogo} alt="Logo" />
    </Container>
  );
};
export default LogoComponent;
