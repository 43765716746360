import { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Broker, brokers, Page } from "../constants/brokers";

type IProps = {
  children?: React.ReactNode;
};

interface IBrokerContext {
  currentBroker: Broker;
  currentPage: Page;
}

export const BrokerContext = createContext<any>({});

export const BrokerProvider: React.FC<IProps> = ({ children }) => {
  const { pathname } = useLocation();

  // retorna somente brokers habilitados
  const brokerList = useMemo(() => brokers().filter((p) => !p.disabled), []);

  const currentBroker = useMemo(
    () =>
      brokerList.find((broker) => pathname.includes(broker.path)) ||
      brokerList[0],
    [pathname, brokerList]
  );

  //   retornar lista de paginas
  const currentPage = useMemo(
    () => brokerList.find((o) => o)?.pages,
    [brokerList]
  );

  useEffect(() => {
    // title
    if (currentBroker.html.title) {
      const title = (document.querySelector("head title") ||
        document.createElement("title")) as HTMLTitleElement;
      title.innerHTML = currentBroker.html.title;
    }

    // favicon
    if (currentBroker?.html.favicon) {
      const link = (document.querySelector("link[rel~='icon']") ||
        document.createElement("link")) as HTMLLinkElement;
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
      link.href = currentBroker?.html.favicon;
    }
  }, [currentBroker]);

  return (
    <BrokerContext.Provider
      value={{
        currentBroker,
        currentPage,
      }}
    >
      {children}
    </BrokerContext.Provider>
  );
};

export const useBroker = (): IBrokerContext => useContext(BrokerContext);
