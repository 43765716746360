import logoFaviconTcs from "../../assets/img/logo-favicon-tcs.jpg";
import { AxiosInstance } from "axios";
import api from "../../services/api";
import { PiArticleThin, PiFile } from "react-icons/pi";
import { GiRailRoad } from "react-icons/gi";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";
import BalancaFerroviariaComponent from "../../module/BalancaFerroviaria/pages/BalancaFerroviaria";
import HistoricoComponent from "../../module/BalancaFerroviaria/pages/Historico";
import BoletimACMComponent from "../../module/BalancaFerroviaria/pages/BoletimACM";
import CarregamentoComponent from "../../module/Carregamento/pages/Carregamento";
import CarregamentoDetalhesComponent from "../../module/Carregamento/pages/CarregamentoDetalhes";
import LogoffComponent from "../../module/Auth/pages/LogoffComponent";
import { RxExit } from "react-icons/rx";

type MenuItem = Required<MenuProps>["items"][number];

interface HTML {
  favicon: string;
  title: string;
}

export interface Page {
  path?: string;
  title?: string;
  name?: string;
  component: string;
}

export interface VersionControl {
  major: string; // Compatibilidade: Existe funcionalidade e /ou codigo incompativel com versoes anteriores;
  minor: string; // Funcionalidades: Novas funcionalidades adicionadas;
  patch: string; // Correcao de bugs: Erros identificados e corrigidos;
  preCandidateRelease: string; // versao: "tentativas" de versoes atreladas ao patch
}

export interface Broker {
  id: string;
  name: string;
  link: string;
  path: string;
  apiUrl: string;
  html: HTML;
  pages: Page[];
  colors: { [key: string]: string };
  initialYear: number;
  icon: any;
  iconStyle?: any;
  iconAlternative?: any;
  iconAlternativeStyle?: any;
  disabled?: boolean;
  defaultPath?: string;
  defaultProviderExistsPath?: string;
  useSSO?: boolean;
  hidePoweredBy?: boolean;
  navigationMenu: MenuItem[];
  routes: any[];
  versionControl: VersionControl;
  api: AxiosInstance;
}

export const brokers = (): Broker[] => {
  return [
    {
      id: "Codar Sistemas",
      name: "Codar Sistemas",
      path: "codar-sistemas",
      apiUrl: "",
      api: api,
      disabled: false,
      link: "",
      colors: {
        primary: "#ff8305",
        secondary: "#ff8305",
        sibebarSelectedColor: "#ff8305",
        sibebarSelectedColorDark: "#ff8305",
      },
      html: {
        favicon: logoFaviconTcs,
        title: "TCS | Powered by Codar Sistemas",
      },
      initialYear: 0,
      icon: logoFaviconTcs,
      iconAlternative: "",
      routes: [
        // {
        //   exact: true,
        //   path: "/",
        //   name: "Início",
        //   Component: HomeComponent,
        // },
        {
          exact: true,
          path: "/",
          name: "Balanca Ferroviária",
          Component: BalancaFerroviariaComponent,
        },
        {
          exact: true,
          path: "/weighingRail",
          name: "Balanca Ferroviária",
          Component: BalancaFerroviariaComponent,
        },
        {
          exact: true,
          path: "historic",
          name: "Histórico",
          Component: HistoricoComponent,
        },
        // {
        //   exact: true,
        //   path: "documentrail",
        //   name: "Boletim ACM",
        //   Component: BoletimACMComponent,
        // },
        {
          exact: true,
          path: "carregamento",
          name: "Carregamento",
          Component: CarregamentoComponent,
        },
        {
          path: "detalhesCarregamento/:id",
          exact: true,
          name: "Detalhes Carregamento",
          Component: CarregamentoDetalhesComponent,
        },
        {
          path: "logoff",
          title: "",
          name: "Logoff",
          Component: LogoffComponent,
        },
      ],
      navigationMenu: [
        // {
        //   key: "home",
        //   icon: <CiHome size={16} />,
        //   label: <Link to={"/"}>Início</Link>,
        // },
        {
          key: "weighingRail",
          icon: <GiRailRoad size={16} />,
          label: <Link to={"/weighingRail"}>Pesagem</Link>,
        },
        {
          key: "historic",
          icon: <PiFile size={16} />,
          label: <Link to={"/historic"}>Histórico</Link>,
        },
        // {
        //   key: "documentrail",
        //   icon: <PiArticleThin size={16} />,
        //   label: <Link to={"/documentrail"}>Boletim ACM</Link>,
        // },
        // {
        //   key: "carregamento",
        //   icon: <PiTrain size={16} />,
        //   label: <Link to={"/carregamento"}>Carregamento</Link>,
        // },
        {
          key: "sair",
          icon: <RxExit size={16} />,
          label: <Link to={"/logoff"}>Sair</Link>,
        },
      ],
      pages: [
        {
          path: "home",
          title: "",
          name: "Início",
          component: "HomeComponent",
        },
        {
          path: "weighingRail",
          title: "",
          name: "Balanca Ferroviária",
          component: "BalancaFerroviariaComponent",
        },
        {
          path: "historic",
          title: "",
          name: "Histórico",
          component: "HistoricoComponent",
        },
        {
          path: "documentrail",
          title: "",
          name: "Boletim ACM",
          component: "BoletimACMComponent",
        },
        {
          path: "carregamento",
          title: "",
          name: "Carregamento",
          component: "CarregamentoComponent",
        },
        {
          path: "carregamento/detalhes/:id",
          title: "",
          name: "Detalhes Carregamento",
          component: "CarregamentoDetalhesComponent",
        },
        {
          path: "logoff",
          title: "",
          name: "Logoff",
          component: "LogoffComponent",
        },
      ],

      versionControl: {
        major: "1",
        minor: "3",
        patch: "2",
        preCandidateRelease: "1",
      },
    },
  ];
};
