import { useMemo } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import PageWrapper from "../components/PageWrapper";
import { useBroker } from "../context/BrokerContext";
import LoginComponent from "../module/Auth/pages/LoginComponent";
import PageNotFound from "../module/PageNotFound";
import OnlyPublicRoute from "./OnlyPublicRoute";
import PrivateRoute from "./PrivateRoutes";

function Routes() {
  const { currentBroker } = useBroker();

  // const defaultPrivateRoute = useMemo(
  //   () => currentBroker.defaultPath,
  //   [currentBroker.defaultPath]
  // );

  const brokerRoutes = useMemo(() => {
    return currentBroker?.routes.map((page, index) => {
      const Element = page?.Component;

      return (
        <Route
          key={index}
          element={<Element />}
          path={page?.path}
          // {...(page?.children
          //   ? {
          //       children: [<Route element={<Element />} path={page?.path} />],
          //     }
          //   : {})}
        />
      );
    });
  }, [currentBroker?.routes]);

  return (
    <Switch>
      <Route
        path="p"
        element={
          <PageWrapper>
            <OnlyPublicRoute />
          </PageWrapper>
        }
      >
        <Route path="login" element={<LoginComponent />} />
      </Route>

      <Route
        path="/"
        element={
          <PageWrapper sideBar={true} header={true} overflow={true}>
            <PrivateRoute />
          </PageWrapper>
        }
      >
        {brokerRoutes}

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Switch>
  );
}

export default Routes;
