import { useEffect } from "react";
import { useAuth } from "../../../context/AuthContext";

const LogoffComponent = () => {
  const { handleSignOut } = useAuth();

  useEffect(() => {
    handleSignOut();
  }, [handleSignOut]);
  return <></>;
};

export default LogoffComponent;
