import styled from "styled-components";

export const Wrapper = styled.div`
  width: "100%";
  min-height: 100%;
`;

export const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 15px;
`;
