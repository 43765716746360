import { Card as CCard } from "antd";
import styled from "styled-components";

export const Card = styled(CCard)`
  width: "100%";
  /* height: "100%" !important; */
  box-sizing: "border-box";
  border-radius: 10px;

  .ant-card-head {
    min-height: 45px !important;
  }

  .ant-card-body {
    padding: 10px;
  }
`;
