import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { Container, Header, Title, Box, Body } from "./style";
import _ from "lodash";
import type { ColumnsType } from "antd/es/table";

interface IBoletimMRS {
  boletimType: "Vazio" | "Carregado";
  content: any;
}
const BoletimMRS: React.FC<IBoletimMRS> = ({ boletimType, content }) => {
  const [posicaoVagao, setPosicaoVagao] = useState<any>([]);
  const [identificacaoVagao, setIdentificacaoVagao] = useState<any>([]);
  const [tipoVagao, setTipoVagao] = useState<any>([]);

  const ColumnsTableHistoric: ColumnsType<Record<any, any>> = [
    {
      align: "center",
      title: "Posição",
      dataIndex: "posicao",
      key: "posicao",
      filterSearch: true,
      filters: posicaoVagao,
      onFilter: (value: any, record: any) => record.posicao === value,
      responsive: ["xs", "md", "lg"],
    },
    {
      align: "center",
      title: "Veículo",
      dataIndex: "veiculo",
      key: "veiculo",
      filterSearch: true,
      filters: identificacaoVagao,
      onFilter: (value: any, record: any) => record.veiculo === value,
      responsive: ["xs", "md", "lg"],
    },
    {
      align: "center",
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      filterSearch: true,
      filters: tipoVagao,
      onFilter: (value: any, record: any) => record.tipo === value,
      responsive: ["xs", "md", "lg"],
    },
    {
      align: "center",
      title: "TU",
      dataIndex: "tu",
      key: "tu",
      responsive: ["xs", "md", "lg"],
    },
    {
      align: "center",
      title: "TB",
      dataIndex: "tb",
      key: "tb",
      responsive: ["xs", "md", "lg"],
    },
    {
      align: "center",
      title: "Lotação Nominal",
      dataIndex: "lotacaoNominal",
      key: "lotacaoNominal",
      responsive: ["xs", "md", "lg"],
    },
  ];

  useEffect(() => {
    const posicao = (content?.vagoes || []).map((e: any) => ({
      text: e?.posicao,
      value: e?.posicao,
    }));
    const identificacao = (content?.vagoes || []).map((e: any) => ({
      text: e?.veiculo,
      value: e?.veiculo,
    }));

    const tipo = _.groupBy(content?.vagoes, "tipo");

    setPosicaoVagao(posicao);
    setIdentificacaoVagao(identificacao);
    setTipoVagao(Object.keys(tipo).map((e: any) => ({ text: e, value: e })));
  }, [content?.vagoes]);

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Title>BOLETIM</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Header>
            <Box>
              <p>
                <b>
                  <u>TREM</u>
                </b>
              </p>
              <p>{content?.trem || ""}</p>
            </Box>
            <Box>
              <b>
                <b>
                  <u>ORIGEM</u>
                </b>
              </b>
              <p>{content?.origem || ""}</p>
            </Box>
            <Box>
              <b>
                <p>
                  <u>DESTINO</u>
                </p>
              </b>
              <p>{content?.destino || ""}</p>
            </Box>
            <Box>
              <b>
                <u>TIPO TREM</u>
              </b>
              <p>{content?.tipoTrem || ""}</p>
            </Box>
            <Box>
              <b>
                <u>QUANTIDADE LOCOMOTIVAS</u>
              </b>
              <p>{content?.quantidadeLocomotivas || ""}</p>
            </Box>
            <Box>
              <b>
                <u>QUANTIDADE VAGÕES</u>
              </b>
              : <p>{content?.quantidadeVagoes || ""}</p>
            </Box>
            <Box>
              <b>
                <u>TABELA</u>
              </b>
              <p>{content?.tabela || ""}</p>
            </Box>
            <Box>
              <b>
                <u>COMPRIMENTO</u>
              </b>
              <p>{content?.comprimento || ""}</p>
            </Box>
            <Box>
              <b>
                <u>TU VAGÕES</u>
              </b>
              <p>{content?.tu || ""}</p>
            </Box>
            <Box>
              <b>
                <u>TARA VAGÕES</u>
              </b>
              <p>{content?.taraVagao || ""}</p>
            </Box>
            <Box>
              <b>
                <u>PESO TREM</u>
              </b>
              <p>{content?.pesoTrem || ""}</p>
            </Box>
            <Box>
              <b>
                <u>VAGÕES TB MÍNIMA</u>
              </b>
              <p>{content?.vagoesTbMinimo || ""}</p>
            </Box>
            <Box>
              <b>
                <u>CAPACIDADE TABELA</u>
              </b>
              <p>{content?.capacidadeTabela || ""}</p>
            </Box>
          </Header>
        </Col>
      </Row>

      <Row>
        <Body>
          <Col span={24}>
            <Table
              dataSource={content?.vagoes || []}
              size="small"
              columns={ColumnsTableHistoric}
              scroll={{ x: `100vw`, y: 100 }}
            />
          </Col>
        </Body>
      </Row>
    </Container>
  );
};

export default BoletimMRS;
